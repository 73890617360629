import { Avatar, Typography } from "antd"
import React from "react"
import MessageContent from "./MessageContent"

const { Text } = Typography

const ChatContainerMe = ({
  avatar,
  name,
  content,
  content_type,
  time,
  content_attributes,
}) => {
  return (
    <div className="flex mb-5 justify-end items-end mr-4">
      <Text className="text-[11px] !text-[#595959] mr-2">{time}</Text>
      <div className="flex flex-col items-start p-4 rounded-2xl bg-[#FDEBEE] overflow-x-auto">
        <div className="flex items-start border-b border-b-[#d3d3d3] w-full pb-2">
          <div>
            <Avatar
              style={{
                backgroundColor: "#FA541C",
                verticalAlign: "middle",
              }}
              size="small"
            >
              {avatar}
            </Avatar>
          </div>
          <div className="ml-[10px]">
            <Text className="!text-[#343434] text-[13px] font-bold">
              {name}
            </Text>
          </div>
        </div>
        <div>
          <MessageContent
            content={content}
            content_type={content_type}
            content_attributes={content_attributes}
          />
        </div>
      </div>
    </div>
  )
}

export default ChatContainerMe
