import React from "react";
import { Divider } from "antd";

const HeaderChat = ({ title }) => {
  return (
    <div>
      <h3
        style={{
          color: "#e60020",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        {title}
      </h3>
      <Divider
        style={{
          backgroundColor: "#e60020",
          marginTop: "14px",
        }}
      />
    </div>
  );
};

export default HeaderChat;
