import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import HeaderChat from "./HeaderChat"
import { useContext } from "react"
import FormChat from "./FormChat"
import { Spin } from "antd"
import InfiniteScroll from "react-infinite-scroll-component"
import ChatContainerMe from "./ChatContainerMe"
import ChatContainer from "./ChatContainer"
import { AccountContext } from "../../../../context/account"
import { getAllMess, lastSeen } from "../../../../services/user"
import tranferTime from "../../../../components/tranferTime"
import { getListUserOfStore } from "../../../../services/chat"
const Chat = ({ infomationConservation, nameBuyer, store_id }) => {
  const [dataMessage, setDataMessage] = useState([])
  const { user } = useContext(AccountContext)

  const [hasMore, setHasMore] = useState(true)
  const [isLoadingGetMessage, setIsLoadingGetMessage] = useState(true)

  const [idBefore, setIdBefore] = useState(null)

  const getAllMessage = useCallback(async () => {
    if (infomationConservation && user) {
      const { response } = await getAllMess(
        {
          store_id: infomationConservation.seller_id,
          conversation_id: infomationConservation.conversation_id,
          store_partner_id: infomationConservation.buyer_id,
        },
        { before: idBefore }
      )
      console.log("reasssss", response)
      if (response.status === 200) {
        const newData = response.data?.data?.map((item) => ({
          id: item.message_type === 1,
          avatar: item.sender.name[0],
          name: item.sender.name,
          nameBuyer: item.message_type === 1 ? "" : nameBuyer,
          content: item.content,
          content_type: item.content_attributes.items
            ? item.content_attributes?.items[0]?.type === "json"
              ? "json"
              : "text"
            : "text",
          content_attributes: item.content_attributes,
          itemStore: item.content_attributes?.items?.[0]?.product,
          time: tranferTime(item.created_at),
        }))
        if (newData?.length < 20) {
          setHasMore(false)
        }
        setIdBefore(response.data?.data?.[0]?.id || null)
        setDataMessage(newData?.reverse())
        setIsLoadingGetMessage(false)
      }
    }
  }, [infomationConservation, user])

  const fetchMoreData = () => {
    // a fake async api call like which sends
    setTimeout(async () => {
      const { response, data } = await getAllMess(
        {
          store_id: infomationConservation.seller_id,
          conversation_id: infomationConservation.conversation_id,
          store_partner_id: infomationConservation.buyer_id,
        },
        { before: idBefore }
      )
      if (response.status === 200) {
        const newData = response.data?.data?.map((item) => ({
          id: item.message_type === 1,
          avatar: item.sender.name[0],
          name: item.sender.name,
          nameBuyer: item.message_type === 1 ? "" : nameBuyer,
          content: item.content,
          content_type: item.content_attributes.items
            ? item.content_attributes?.items[0]?.type === "json"
              ? "json"
              : "text"
            : "text",
          content_attributes: item.content_attributes,
          itemStore: item.content_attributes?.items?.[0]?.product,
          time: tranferTime(item.created_at),
        }))
        if (newData?.length < 20) {
          setHasMore(false)
        }
        setIdBefore(response.data?.data?.[0]?.id || null)
        setDataMessage(dataMessage.concat(newData?.reverse()))
      }
    }, 250)
  }

  const webSocket = useRef(null)

  useEffect(() => {
    if (infomationConservation) {
      webSocket.current = new WebSocket(
        process.env.GATSBY_NEXT_PUBLIC_URL_WEBSOCKET
      )
      webSocket.current.onopen = (e) => {
        console.log("onopen:", e)
        webSocket.current.send(
          JSON.stringify({
            command: "subscribe",
            identifier:
              '{"channel":"RoomChannel","pubsub_token":"' +
              infomationConservation.seller_pubsub +
              '"}',
          })
        )
      }

      webSocket.current.onclose = (e) => {
        console.log("closed:", e)
      }
    }

    return () => {
      webSocket.current.close()
    }
  }, [infomationConservation])

  useEffect(() => {
    if (infomationConservation && user) {
      webSocket.current.onmessage = (e) => {
        const json = JSON.parse(e.data)
        // console.log("Message:", json);
        if (json.type === "welcome") {
          // lets ignore the welcome
        } else if (json.type === "ping") {
          // lets ignore the pings
        } else if (json.type === "confirm_subscription") {
          // lets ignore the confirmation
        } else if (json.message.event === "message.created") {
          const { data } = json.message
          console.log("here comes message", data)

          // checkLastSeen(obj.id)
          //   .then(() => {
          //     console.log("ok");
          //   })
          //   .catch((err) => console.log(err));
          setDataMessage((prev) => [
            {
              id: data.message_type === 1,
              avatar: data.sender.name[0],
              name: data.sender.name,
              nameBuyer: data.message_type === 1 ? "" : nameBuyer,
              content: data.content,
              content_type: data.content_attributes.items
                ? data.content_attributes?.items[0]?.type === "json"
                  ? "json"
                  : "text"
                : "text",
              content_attributes: data.content_attributes,
              itemStore: data.content_attributes?.items?.[0]?.product,
              time: tranferTime(data.created_at),
            },
            ...prev,
          ])
          // lastSeen({
          //   account_id: user?.metadata?.account_id,
          //   conversation_id: infomationConservation.conversation_id,
          // })
        } else if (json.message.event === "conversation.created") {
          // alert(json.message.data);
        } else {
          console.log("Hmm..., I've never seen JSON like this: ", json)
        }
      }
    }
  }, [infomationConservation, user])

  useEffect(() => {
    if (infomationConservation && user) {
      getAllMessage()
    }
  }, [infomationConservation, user])

  const [listUserContainer, setListUserContainer] = useState([])

  const handleGetListUserContainer = async () => {
    const { response } = await getListUserOfStore(store_id)
    if (response.status == 200) {
      setListUserContainer(response.data?.users)
    }
  }

  useEffect(() => {
    if ((infomationConservation, store_id)) {
      handleGetListUserContainer()
    }
  }, [infomationConservation, store_id])

  return (
    <div style={{ background: "#fff", padding: 16, marginTop: "2.3rem" }}>
      <Spin spinning={isLoadingGetMessage}>
        <HeaderChat title="メッセージ 詳細"> </HeaderChat>

        <div
          id="scrollableDiv"
          style={{
            height: 400,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <InfiniteScroll
            dataLength={dataMessage.length}
            next={fetchMoreData}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse={true} //
            hasMore={hasMore}
            loader={<h4>{"読み込み中..."}</h4>}
            scrollableTarget="scrollableDiv"
          >
            {dataMessage.map((item, index) => (
              <div key={index}>
                {item.id ? (
                  <ChatContainerMe
                    avatar={"M"}
                    name={"Mr.Food運営"}
                    content={item.content}
                    content_type={item.content_type}
                    time={item.time}
                    content_attributes={item.content_attributes}
                  />
                ) : (
                  <ChatContainer
                    avatar={item.avatar}
                    name={item.name}
                    content={item.content}
                    time={item.time}
                    nameBuyer={item.nameBuyer}
                    itemStore={item.itemStore}
                    infoUserSend={
                      listUserContainer?.filter(
                        (el) =>
                          el.id == item?.content_attributes?.items?.[0]?.userId
                      )?.[0]
                    }
                    content_attributes={item.content_attributes}
                  />
                )}
              </div>
            ))}
          </InfiniteScroll>
          {dataMessage.length == 0 && "メッセージはまだありません。"}
        </div>

        <FormChat infomationConservation={infomationConservation} />
      </Spin>
    </div>
  )
}

export default memo(Chat)
