import React from "react"
import "../styles.scss"
import AdminChat from "../../../domain/superChat/adminChat"
import Topbar from "../../../components/organisms/topbar"
import CustomLayout from "../../../Layouts/Layout"
import { getSearchParams } from "gatsby-query-params"

const index = () => {
  const query = getSearchParams()
  console.log("queryquery", query)
  return (
    <div className="supperChat">
      <Topbar />
      <CustomLayout selectedPage={<AdminChat store_id={query?.store_id} />} />
    </div>
  )
}

export default index
