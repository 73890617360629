import request from "../customUtil/request"
import request2 from "../customUtil/request2"

export async function checkConversation(data) {
  return request2(`store/conversation/check`, {
    method: "POST",
    data,
  })
}
export async function createContact(data) {
  return request(`/store/chat/contactCreate`, {
    method: "POST",
    data,
  })
}
export async function createChat(data) {
  return request(`/store/chat/conversationCreate/client`, {
    method: "POST",
    data,
  })
}

export async function saveConversation(data) {
  return request(`/admin/conversation/create`, {
    method: "POST",
    data,
  })
}

export async function getListUserOfStore(id) {
  return request(`/store/list-users-in-store/${id}`, {
    method: "GET",
  })
}
