const tranferTime = (date1) => {
  const date2 = Date.now() / 1000
  const diff = Math.abs(date2 - date1)
  const years = Math.floor(diff / (365 * 60 * 60 * 24))
  const months = Math.floor(
    (diff - years * 365 * 60 * 60 * 24) / (30 * 60 * 60 * 24)
  )
  const days = Math.floor(
    (diff - years * 365 * 60 * 60 * 24 - months * 30 * 60 * 60 * 24) /
      (60 * 60 * 24)
  )
  const hours = Math.floor(
    (diff -
      years * 365 * 60 * 60 * 24 -
      months * 30 * 60 * 60 * 24 -
      days * 60 * 60 * 24) /
      (60 * 60)
  )
  const minutes = Math.floor(
    (diff -
      years * 365 * 60 * 60 * 24 -
      months * 30 * 60 * 60 * 24 -
      days * 60 * 60 * 24 -
      hours * 60 * 60) /
      60
  )
  const seconds = Math.floor(
    diff -
      years * 365 * 60 * 60 * 24 -
      months * 30 * 60 * 60 * 24 -
      days * 60 * 60 * 24 -
      hours * 60 * 60 -
      minutes * 60
  )
  if (years > 0) {
    return years + " 年前"
  }
  if (months > 0) {
    return months + " 先月"
  }
  if (days > 0) {
    return days + " 昨日"
  }
  if (hours > 0) {
    return hours + " 時前"
  }
  if (minutes > 0) {
    return minutes + " 分前"
  }
  if (seconds <= 3) {
    return " 今"
  }
  if (seconds > 3) {
    return seconds + " 秒前"
  }
  return null
}

export default tranferTime
