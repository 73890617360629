import { Avatar, Col, Row, Typography } from "antd"
import React, { useEffect, useState } from "react"
import MessageContent from "./MessageContent"

const { Text } = Typography

const ChatContainer = ({
  avatar,
  name,
  nameBuyer,
  content,
  time,
  content_attributes,
  infoUserSend = null,
}) => {
  return (
    <div className="flex items-start mb-5">
      <div>
        <Avatar
          style={{
            backgroundColor: "#FA541C",
            verticalAlign: "middle",
          }}
          size="large"
        >
          {avatar}
        </Avatar>
      </div>
      <div className="ml-[10px]">
        {infoUserSend && (
          <Text className="!text-[#343434] text-[11px] font-bold">{`${
            infoUserSend?.first_name ?? ""
          } ${infoUserSend?.last_name ?? ""}`}</Text>
        )}
        <div className="!text-[#343434] text-[13px] font-bold">{nameBuyer}</div>
        <div className="mt-3">
          <div>
            <MessageContent content={content} time={time} />
            {content_attributes?.items?.[0]?.values?.max?.label && (
              <div
                style={{
                  backgroundColor: "#F5F6F8",
                  padding: "4px 8px",
                  borderRadius: 16,
                  fontSize: 13,
                  color: "#333",
                  minWidth: 300,
                }}
              >
                {content_attributes?.items?.[0]?.values?.max?.label && (
                  <div
                    style={{
                      width: "fit-content ",
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                    }}
                  >
                    <div span={12}>商品大カテゴリ:</div>
                    <div className="whitespace-nowrap">
                      {content_attributes?.items?.[0]?.values?.max?.label}
                    </div>
                  </div>
                )}
                {content_attributes?.items?.[0]?.values?.middle?.label && (
                  <div
                    style={{
                      width: "fit-content ",
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                    }}
                  >
                    <div span={12}>商品中カテゴリ:</div>
                    <div className="whitespace-nowrap">
                      {content_attributes?.items?.[0]?.values?.middle?.label}
                    </div>
                  </div>
                )}
                {content_attributes?.items?.[0]?.values?.small?.label && (
                  <div
                    style={{
                      width: "fit-content ",
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                    }}
                  >
                    <div className="whitespace-nowrap">商品小カテゴリ:</div>
                    <div className="whitespace-nowrap">
                      {content_attributes?.items?.[0]?.values?.small?.label}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatContainer
